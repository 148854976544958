<template>
  <div style="position: relative">
    <GlobalLoader :is_loading="is_loading" />
    <div class="filter mt-2 mb-3">
      <DatePicker
        :getPeriod="(value) => setDate(value)"
        :show_time="true"
        :default_range="defaultRange"
        :clearable="false"
      />
    </div>

    <div style="max-height: 500px; overflow-y: scroll">
      <div v-if="contacts && contacts.results && contacts.results.length">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Country</th>
                <th>Offer</th>
                <th>Contacts Count</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="item in contacts.results">
                <tr
                  class="table-tr-hover"
                  v-for="(offer, offerIndex) in item.by_offer.buckets"
                  :key="`${item.key}-${offer.key}`"
                >
                  <td
                    v-if="offerIndex === 0"
                    :rowspan="item.by_offer.buckets.length"
                  >
                    <country-flag
                      v-if="item.key"
                      :country="item.key"
                      size="small"
                    />
                    {{ item.key }}
                  </td>
                  <td>{{ offer.key }}</td>
                  <td>{{ offer.contacts_count.doc_count }}</td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>

        <CustomPagination
          :count="contacts.count"
          :limit="filter.limit"
          :offset="filter.offset"
          :changeOffset="
            (value) => {
              filter.offset = value;
              fetchContactsStat();
            }
          "
        />
      </div>
      <span
        v-else-if="!is_loading"
        class="d-flex align-center justify-center text-caption"
        >No data found</span
      >
    </div>
  </div>
</template>

<script>
import GlobalLoader from "@/components/GlobalLoader.vue";
import DatePicker from "@/components/DatePicker.vue";
import { loadNewContacts } from "@/store/modules/contacts/api";
import { requestStatus, buildFilterString } from "@/services/services";
import CustomPagination from "@/components/CustomPagination.vue";
import CountryFlag from "vue-country-flag";
import moment from "moment";
export default {
  components: {
    GlobalLoader,
    DatePicker,
    CustomPagination,
    CountryFlag,
  },
  data() {
    return {
      defaultRange: null,
      is_loading: false,
      contacts: null,
      filter: {
        limit: 30,
        offset: 0,
      },
    };
  },
  computed: {
    moment: () => moment,
  },
  created() {
    this.defaultRange = {
      date_from: moment().startOf("isoWeek").toDate(),
      date_to: moment
        .utc()
        .add(new Date().getTimezoneOffset(), "minutes")
        .toDate(),
    };
  },
  methods: {
    setDate(value) {
      ["date_from", "date_to"].forEach((key) => {
        value[key] ? (this.filter[key] = value[key]) : delete this.filter[key];
      });
      this.fetchContactsStat();
    },
    fetchContactsStat() {
      this.is_loading = true;
      loadNewContacts(buildFilterString(this.filter))
        .then((response) => {
          if (response.status === requestStatus.success) {
            this.contacts = response.response.data;
          } else {
            this.$toaster.error(
              response.messages || "Oops, something went wrong."
            );
          }
        })
        .finally(() => (this.is_loading = false));
    },
  },
};
</script>

<style lang="scss" scoped>
.table-tr-hover {
  &:hover {
    background: white !important;
  }
}
</style>
