<template>
  <date-picker
    :disabled-date="notAfterToday"
    v-model="range"
    placeholder="Select period"
    :format="show_time ? DATETIME_FORMAT : DATE_FORMAT"
    range
    :lang="lang"
    @open="onOpen"
    ref="date_picker"
    :type="show_time ? 'datetime' : 'date'"
    :clearable="clearable"
    :shortcuts="shortcuts"
  >
  </date-picker>
</template>

<script>
import DatePicker from "vue2-datepicker";
import {
  createDateInUTC,
  DATE_API_FORMAT,
  DATE_FORMAT,
  DATETIME_FORMAT,
  getStartOfWeek,
} from "@/services/services";
import moment from "moment";

const today = createDateInUTC();

export default {
  name: "CalendarPeriod",
  components: { DatePicker },
  props: {
    setPeriod: Function,
    is_past: Boolean,
    hide_default_range: Boolean,
    getPeriod: Function,
    default_range: {
      type: Object,
      default: undefined,
    },
    show_time: Boolean,
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    range: [],
    open: false,
    skip_update_range: false,
    lang: {
      formatLocale: {
        firstDayOfWeek: 1,
      },
    },
    shortcuts: [
      {
        text: "Today",
        onClick() {
          let dt = createDateInUTC();
          return [new Date(dt.setHours(0, 0, 0, 1)), createDateInUTC()];
        },
      },
      {
        text: "Yesterday",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 1);
          return [
            new Date(dt.setHours(0, 0, 0, 1)),
            new Date(dt.setHours(23, 59, 59, 59)),
          ];
        },
      },
      {
        text: "Last 3 days",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 2);
          return [new Date(dt.setHours(0, 0, 0, 1)), createDateInUTC()];
        },
      },
      {
        text: "Last 7 days",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 6);
          return [new Date(dt.setHours(0, 0, 0, 1)), createDateInUTC()];
        },
      },
      {
        text: "Last 30 Days",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - 29);
          return [new Date(dt.setHours(0, 0, 0, 1)), createDateInUTC()];
        },
      },
      {
        text: "Current Month",
        onClick() {
          // return getCurrentMonth();
          let dt = createDateInUTC();
          dt.setDate(1);

          const currentDate = new Date();
          const currentDay = new Date(currentDate);

          return [new Date(dt.setHours(0, 0, 0, 1)), createDateInUTC()];
        },
      },
      {
        text: "Current Week",
        onClick() {
          let dt = createDateInUTC();
          dt.setDate(dt.getDate() - dt.getDay() + 1);
          // change td => getStartOfWeek()
          const currentDate = new Date();
          const currentDayOfWeek = currentDate.getDay();
          return currentDayOfWeek === 1
            ? [new Date(dt.setHours(0, 0, 0, 0)), createDateInUTC()]
            : [
                new Date(getStartOfWeek().setHours(0, 0, 0, 1)),
                createDateInUTC(),
              ];
        },
      },
      {
        text: "Last Week",
        onClick() {
          let dt_from = createDateInUTC();
          dt_from.setDate(dt_from.getDate() - dt_from.getDay() + 1 - 7);
          let dt_to = createDateInUTC();
          dt_to.setDate(dt_to.getDate() - dt_to.getDay());
          return [
            new Date(dt_from.setHours(0, 0, 0, 0)),
            new Date(dt_to.setHours(0, 0, 0, 0)),
          ];
        },
      },
      {
        text: "Last Month",
        onClick() {
          let dt_from = createDateInUTC();
          dt_from.setMonth(dt_from.getMonth() - 1);
          dt_from.setDate(1);

          let dt_to = new Date(dt_from);
          dt_to.setMonth(dt_to.getMonth() + 1);
          dt_to.setDate(0);

          return [
            new Date(dt_from.setHours(0, 0, 0, 0)),
            new Date(dt_to.setHours(0, 0, 0, 0)),
          ];
        },
      },
    ],
  }),
  created() {
    if (this.default_range) {
      this.range = [this.default_range.date_from, this.default_range.date_to];
    }
  },
  computed: {
    DATE_FORMAT: () => DATE_FORMAT,
    DATETIME_FORMAT: () => DATETIME_FORMAT,
    API_FORMAT: function () {
      return DATE_API_FORMAT;
    },
  },
  methods: {
    notAfterToday(date) {
      return this.is_past ? false : date > today;
    },
    onOpen() {
      let dt = createDateInUTC();
      let date_to = this.range[1] ? this.range[1] : createDateInUTC();
      if (date_to.getMonth() === dt.getMonth()) {
        dt.setDate(1);
        if (dt.getMonth() === 0) {
          dt.setMonth(11);
          dt.setFullYear(dt.getFullYear() - 1);
        } else {
          dt.setMonth(dt.getMonth() - 1);
        }
        if (
          !this.show_time &&
          this.$refs.date_picker.$refs.popup.$children &&
          this.$refs.date_picker.$refs.popup.$children[0]
        ) {
          this.$refs.date_picker.$refs.popup.$children[0].calendars = [
            dt,
            createDateInUTC(),
          ];
        }
        if (
          this.show_time &&
          this.$refs.date_picker.$refs.popup.$children &&
          this.$refs.date_picker.$refs.popup.$children[0] &&
          this.$refs.date_picker.$refs.popup.$children[0].$children[0]
        ) {
          this.$refs.date_picker.$refs.popup.$children[0].$children[0].calendars =
            [dt, createDateInUTC()];
        }
      }
    },
  },
  watch: {
    range(newValue) {
      if (!this.skip_update_range) {
        console.log(newValue);
        let date_from = moment(newValue[0]).format("YYYY-MM-DDTHH:mm:ss.SSS");
        let date_to = moment(newValue[1]).format("YYYY-MM-DDTHH:mm:ss.SSS");
        let period = {
          date_from: date_from,
          date_to: date_to,
        };
        console.log(period);
        if (this.getPeriod) {
          this.getPeriod(period);
        }
        if (this.skip_update_range) {
          this.range = [
            date_from && new Date(date_from),
            date_to && new Date(date_to),
          ];
        }
      } else {
        this.skip_update_range = false;
      }
    },
  },
};
</script>

<style scoped></style>
