import { render, staticRenderFns } from "./NewContacts.vue?vue&type=template&id=eed5a0b4&scoped=true&"
import script from "./NewContacts.vue?vue&type=script&lang=js&"
export * from "./NewContacts.vue?vue&type=script&lang=js&"
import style0 from "./NewContacts.vue?vue&type=style&index=0&id=eed5a0b4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eed5a0b4",
  null
  
)

export default component.exports